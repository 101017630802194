<template>
  <div>
    <p class="text-2xl mb-6">Inventaire vidéo</p>
    <v-card class="mb-6 pa-2" outlined>
      <filters />
    </v-card>

    <v-row>
      <v-col cols="12" md="12">
        <results />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted } from "@vue/composition-api";

import Filters from "@/components/regie/video-inventory/filters/Filters.vue";
import Results from "@/components/regie/video-inventory/tables/Results";
import useProxy from "@/hooks/useProxy";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "VideoInventory",
  components: {
    Filters,
    Results,
  },
  setup(props, { emit }) {
    const { route } = useProxy();

    // =>  METHODS

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS
    onMounted(async () => {
      saveRmraView(route);
    });

    // =>  DATA
    return {};
  },
};
</script>

<style></style>
