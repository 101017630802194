<template>
  <div>
    <v-row class="d-flex ma-3">
      <v-col cols="6">
        <date-filter store="regie" periodType="past-only" />
      </v-col>
      <v-col class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";

import useAxios from "@/hooks/useAxios";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
  },
  setup(props, { emit }) {
    const { isLoading } = useAxios();

    // =>  METHODS

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS

    // =>  DATA
    return {
      isLoading,
    };
  },
};
</script>

<style></style>
