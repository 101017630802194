<template>
  <v-card outlined>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12"> Résultats </v-col>
      </v-row>
      <dot-menu
        :isLoading="page.loading"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData: excelButton.data,
          filename: `inventaire_video-${startDate}_${endDate}.xlsx`,
          disabled: exportDisabled,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="page.headers"
        :items="page.items"
        :loading="page.loading"
        :hide-default-footer="true"
        :disable-pagination="true"
        :disable-filtering="true"
        :sort-by="'site'"
      />
      <!-- :disable-sort="true" -->
    </v-card-text>
    <progress-bar :download="download" />
  </v-card>
</template>

<script>
import { computed, del, reactive, set, watch } from "@vue/composition-api";

import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";

import { formatNumber, formatPercent } from "@/utils/formatting";

export default {
  name: "Results",
  components: {
    DotMenu,
    ProgressBar,
  },
  setup(props, { emit }) {
    const { store } = useProxy();
    const { axiosGet } = useAxios();

    const page = reactive({
      loading: false,
      headers: [
        {
          text: "Site",
          align: "start",
          value: "site",
        },
        {
          text: "Monetizable plays",
          value: "monetizable",
        },
        {
          text: "Plays",
          value: "plays",
        },
        {
          text: "Ratio",
          value: "ratio",
        },
      ],
      items: [],
    });

    const download = reactive(download_initial_state());

    const excelButton = reactive({
      data: new Blob([""], { type: "text/plain" }),
    });

    // =>  METHODS
    const checkDates = () => {
      if (
        !startDate.value ||
        startDate.value.length !== 10 ||
        !endDate.value ||
        endDate.value.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      return true;
    };
    const formatResult = (data) => {
      return Object.entries(data).map(([key, item]) => ({
        site: key,
        monetizable: formatNumber(item.monetizable),
        plays: formatNumber(item.plays),
        ratio: formatPercent(item.ratio),
      }));
    };

    const getData = async () => {
      if (applyFilters.value && !page.loading && checkDates()) {
        page.loading = true;
        const { data } = await axiosGet("/regie/video-inventory", {
          start_date: startDate.value,
          end_date: endDate.value,
        });

        page.items = formatResult(data);

        page.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        store.dispatch("common/updateApplyFilters", false);
      }
    };

    const exportExcel = async () => {
      if (!checkDates()) {
        return;
      }

      page.loading = true;
      download.running = true;
      const { data } = await axiosGet(
        "/regie/video-inventory/export_xlsx",
        {
          start_date: startDate.value,
          end_date: endDate.value,
        },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      excelButton.data = data;
      page.loading = false;
    };

    // =>  COMPUTED
    const applyFilters = computed(
      () => store.getters["common/getApplyFilters"]
    );
    const startDate = computed(() => store.getters["regie/getDates"][0]);
    const endDate = computed(() => store.getters["regie/getDates"][1]);

    const exportDisabled = computed(
      () => page.loading || page.items.length === 0
    );

    // =>  WATCH
    watch(applyFilters, () => {
      getData();
    });

    watch(
      () => download.percentCompleted,
      (newValue) => {
        if (newValue >= 100) {
          setTimeout(() => {
            const initialState = download_initial_state();
            for (const [key, value] of Object.entries(initialState)) {
              set(download, key, value);
            }
            for (const key of Object.keys(download)) {
              if (!(key in initialState)) {
                del(download, key);
              }
            }
          }, 500);
        }
      }
    );

    // =>  HOOKS

    // =>  DATA
    return {
      page,
      download,
      exportExcel,
      excelButton,
      exportDisabled,
      startDate,
      endDate,
    };
  },
};
</script>

<style></style>
